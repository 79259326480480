import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './slider3.css';

// Функция для импорта изображений из нескольких папок
const importImagesFromFolder = (folderName) => {
  if (folderName === 'IndividualShoot') {
    return require.context(
      '../../assets/images/IndividualShoot',
      false,
      /\.(jpg|jpeg|png)$/
    ).keys().map((fileName) =>
      require('../../assets/images/IndividualShoot/' + fileName.replace('./', ''))
    );
  } else if (folderName === 'FamilyShoot') {
    return require.context(
      '../../assets/images/FamilyShoot',
      false,
      /\.(jpg|jpeg|png)$/
    ).keys().map((fileName) =>
      require('../../assets/images/FamilyShoot/' + fileName.replace('./', ''))
    );
  } else if (folderName === 'LoveStory') {
    return require.context(
      '../../assets/images/LoveStory',
      false,
      /\.(jpg|jpeg|png)$/
    ).keys().map((fileName) =>
      require('../../assets/images/LoveStory/' + fileName.replace('./', ''))
    );
  } else if (folderName === 'ChildrenShoot') {
    return require.context(
      '../../assets/images/ChildrenShoot',
      false,
      /\.(jpg|jpeg|png)$/
    ).keys().map((fileName) =>
      require('../../assets/images/ChildrenShoot/' + fileName.replace('./', ''))
    );
  } else if (folderName === 'ExpressShoot') {
    return require.context(
      '../../assets/images/ExpressShoot',
      false,
      /\.(jpg|jpeg|png)$/
    ).keys().map((fileName) =>
      require('../../assets/images/ExpressShoot/' + fileName.replace('./', ''))
    );
  } else if (folderName === 'WeddingShoot') {
    return require.context(
      '../../assets/images/WeddingShoot',
      false,
      /\.(jpg|jpeg|png)$/
    ).keys().map((fileName) =>
      require('../../assets/images/WeddingShoot/' + fileName.replace('./', ''))
    );
  } else if (folderName === 'Home') {
    return require.context(
      '../../assets/images/Home',
      false,
      /\.(jpg|jpeg|png)$/
    ).keys().map((fileName) =>
      require('../../assets/images/Home/' + fileName.replace('./', ''))
    );
  }

  return []; // Возвращает пустой массив, если папка не найдена
};

function ImageSlider({ folderName }) {
  const images = importImagesFromFolder(folderName);

  return (
    <div style={{ width: '400px', height: '800px', margin: 'auto' }}>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={10}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default ImageSlider;
