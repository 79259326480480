import React from 'react';
import ImageSlider3 from '../components/slider3/slider3';

function Home() {
  return (
    <div className="App">
      <h3>Семейная фотосессия</h3>
      <ImageSlider3 folderName="FamilyShoot" />
    </div>
  );
}

export default Home;