import React from 'react';
import './Pages.css';


function ImportantInformation() {
  return (
    <div className="App">
      <p>Оплата производится полностью в день съёмки.</p>
      <p>Если вы не хотите, чтобы я делилась вашими фото в своих социальных сетях, просьба сообщить об этом.</p>
      <p>Аренда студии, работа визажиста и стилиста, если это нужно для съёмки, оплачивается отдельно.</p>
    </div>
  );
}

export default ImportantInformation;