import React from 'react';
import ImageSlider3 from '../components/slider3/slider3';
import './Pages.css';


function Home() {
  return (
    <div className="App">
      <h3>Снимать - значит, чувствовать, любить и наслаждаться</h3>
      <ImageSlider3 folderName="Home" />
    </div>
  );
}

export default Home;