import React from 'react';
import ImageSlider3 from '../components/slider3/slider3';

function Home() {
  return (
    <div className="App">
      <h3>Индивидуальная съёмка</h3>
      <p>Индивидуальная съёмка</p>
      <p>3500 р/час</p>
      <p>Что входит в стоимость:</p>
      <p>. Обсуждение локации </p>
      <p>. Советы по подбору образа</p>
      <p>. 45-50 фото в цветокоррекции (за 1 час)</p>
      <ImageSlider3 folderName="IndividualShoot" />
    </div>
  );
}

export default Home;