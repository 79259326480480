import React from 'react';
import './SocialLinks.css'; // Файл CSS для стилизации
import { FaInstagram, FaVk, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';

function SocialLinks() {
  return (
    <div className="social-links">
      <a
        href="https://www.instagram.com/dashuta2012/"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon instagram"
      >
        <FaInstagram />
      </a>
      <a
        href="https://vk.com/club227298623"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon vk"
      >
        <FaVk />
      </a>
      <a
        href="https://wa.me/79166122324"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon whatsapp"
      >
        <FaWhatsapp />
      </a>
      <a
        href="https://t.me/Dashuta2012"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon telegram"
      >
        <FaTelegramPlane />
      </a>
    </div>
  );
}

export default SocialLinks;
