import React from "react";
import './header.css'
import photoDaria from '../../assets/images/Daria.jpg';
import SocialLinks from '../SocialIcons/socialicons';

const Header = ({toDo, done}) => {
    return (
      <div className="header">
        <h1>Дарья Пузикова</h1>
        <h2>Фотограф</h2>
        <h3>Персональный сайт</h3>
        <img src={photoDaria} alt="Daria" className="photoDaria"/>
        <SocialLinks />
        <p>+7 916 612 2324</p>
      </div>
    );
  };

export default Header;